import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'singularFilter' })
export class SingularPipe implements PipeTransform {
  /**
   * Transform
   *
   * @param {any[]} items
   * @param {string} searchText
   * @returns {any[]}
   */
  transform(interval:number): string {
    if(interval == 3){
      return "Quarter"
    }
    else if(interval == 6){
      return "Half-Year"
    }
    else if(interval == 12){
      return "Year"
    }
    else if(interval == 4){
        return "(4) month"
    }
    else{
      return "Month"
    }
  }
}