<div class="grid-container">
    <h1 class="mat-h1">Invite User</h1>
    <mat-grid-list cols="2" rowHeight="350px">
        <mat-grid-tile [colspan]="1" [rowspan]="1">
            <mat-card class="dashboard-card">
                <mat-card-header>
                    <mat-card-title>
                        Send an invitation<br><br>
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content class="dashboard-card-content">
                    <form [formGroup]="inviteUserForm" (ngSubmit)="onSubmit();">
                        <mat-form-field class="col-8">
                            <mat-label>Name</mat-label>
                            <input matInput formControlName="name" placeholder="Vipin" required>
                        </mat-form-field>
                        <mat-form-field class="col-8">
                            <mat-label>Email</mat-label>
                            <input matInput formControlName="email" placeholder="vipin@honeywell.com" required>
                            <button type="button" mat-icon-button matSuffix>
                                <mat-icon>email</mat-icon>
                            </button>
                        </mat-form-field>
                        <br>
                        <div class="col-8">
                            <button mat-button type="button" (click)="inviteUserForm.reset();">Cancel</button>
                            <button mat-button type="submit">Submit
                                <mat-icon *ngIf="loader">
                                    <mat-spinner color="primary" diameter="20">
                                    </mat-spinner>
                                </mat-icon>
                            </button>
                        </div>
                       
                    </form>
                </mat-card-content>
            </mat-card>
        </mat-grid-tile>
    </mat-grid-list>

    <mat-grid-list cols="2" rowHeight="350px">
        <mat-grid-tile [colspan]="2" [rowspan]="1">
            <mat-card class="dashboard-card">
                <mat-card-header>
                    <mat-card-title>
                        Resend invitation
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content class="dashboard-card-content">
                    <div class="row">
                        <div class="col-4" *ngFor="let item of invitedUserList; let i = index">
                            <mat-card>
                                <mat-card-content>Name : {{item.name}}</mat-card-content>
                                <mat-card-content>Email : {{item.email}}</mat-card-content>
                                <button mat-button (click)="resendEmail(i);">Resend Invite
                                    <mat-icon *ngIf="resendLoader">
                                        <mat-spinner color="primary" diameter="20">
                                        </mat-spinner>
                                    </mat-icon>
                                </button>
                            </mat-card>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </mat-grid-tile>
    </mat-grid-list>
</div>