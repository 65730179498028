import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { map } from 'rxjs/operators';
import { InviteUser } from 'src/app/model/InviteUser';
import { MMSHttpResponse, MMSHttpResponseGeneric } from 'src/app/model/response';
import { InviteUserService } from '../../services/invite-user.service';

@Component({
  selector: 'app-invite-user',
  templateUrl: './invite-user.component.html',
  styleUrls: ['./invite-user.component.scss']
})
export class InviteUserComponent implements OnInit {

  resData: MMSHttpResponse;
  inviteUserForm: FormGroup;
  loader = false;
  resendLoader = false;
  invitedUserList: InviteUser [];

  cards = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map(({ matches }) => {
      if (matches) {
        return [
          { title: 'Send an invitation', cols: 1, rows: 1 },
          { title: 'Resend invitation', cols: 2, rows: 1 },
        ];
      }

      return [
        { title: 'Send an invitation', cols: 1, rows: 1 },
        { title: 'Resend invitation', cols: 2, rows: 1 },
      ];
    })
  );

  constructor(private _inviteUserService: InviteUserService,private _snackBar:MatSnackBar, fb:FormBuilder,private breakpointObserver: BreakpointObserver) { 
    this.inviteUserForm = fb.group({
        email: ['',Validators.required],
        name: ['',Validators.required]
    })
  }

  ngOnInit(): void {
    this.getInvitedUserList();
  }

  onSubmit(){

    if(this.inviteUserForm.invalid){
      return false;
    }
    this.loader = true;

    this._inviteUserService.inviteUser(this.inviteUserForm.value.email,this.inviteUserForm.value.name)
    .subscribe((res: MMSHttpResponse) => {
      this.resData = res;
      console.log("data", this.resData);
      if (this.resData.successMessage !== null) {
        this.getInvitedUserList();
        this._snackBar.open(this.resData.successMessage, '', {
          duration: 5000
        });
        this.loader = false;
        this.inviteUserForm.reset();
      }
      else {
        this._snackBar.open(this.resData.errorMessage, '', {
          duration: 5000
        });
        this.loader = false;
      }
    }, err => {
      console.log(err);
    });
  }

 

  getInvitedUserList(){
    this._inviteUserService.getInvitedUsers().subscribe((res:MMSHttpResponseGeneric<InviteUser[]>)=>{
      this.invitedUserList = res.responseBody;
    });
  }

  resendEmail(index:number){
    
    this.resendLoader = true;
    this._inviteUserService.resendEmail(this.invitedUserList[index].email).subscribe((res:MMSHttpResponse)=>{
      console.log();
      this.resData= res;
      this._snackBar.open(this.resData.successMessage, '', {
        duration: 5000
      });
      this.resendLoader = false;
    });
  }

}
