import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EmailResetLinkComponent } from './auth/component/email-reset-link/email-reset-link.component';
import { InviteConfirmComponent } from './auth/component/invite-confirm/invite-confirm.component';
import { LoginComponent } from './auth/component/login/login.component';
import { ResetPasswordResponseComponent } from './auth/component/reset-password-response/reset-password-response';
import { ResetPasswordComponent } from './auth/component/reset-password/reset-password.component';
import { AuthGuard } from './auth/guard/auth.guard';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [AuthGuard]
  },
  { path: 'forgot-password', component: EmailResetLinkComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: 'invite-confirm', component: InviteConfirmComponent },
  { path: 'reset-password-success', component: ResetPasswordResponseComponent },
  {
    path: 'page',
    loadChildren: () => import('./pages/page.module').then(m => m.PageModule),

    //canActivate: [RandomGuard],
    //canLoad: [RandomGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],

  exports: [RouterModule]
})
export class AppRoutingModule { }
