import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { MMSHttpResponse } from 'src/app/model/response';
import { environment } from 'src/environments/environment';
import { AuthService } from '../../services/auth.service';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-forgotpassword',
  templateUrl: './email-reset-link.component.html',
  styleUrls: ['./email-reset-link.component.scss']
})
export class EmailResetLinkComponent implements OnInit {
  matcher = new MyErrorStateMatcher();
  loading = false;
  hide=true;
  resData: MMSHttpResponse;
  forgotPwdForm: FormGroup
  constructor(fb: FormBuilder,private _authService: AuthService,private _router: Router,private _snackBar: MatSnackBar) { 
    this.forgotPwdForm = fb.group({
      email: ['',[Validators.required,Validators.email]],
      redirectUri: [`${environment.resetPasswordRedirectUri}`]
    })
  }

  ngOnInit(): void {
  }

  onSubmit(){
    // stop here if form is invalid
    if (this.forgotPwdForm.invalid) {
      return;
    }
    this.loading =true;
    this._authService.emailResetLink(this.forgotPwdForm.value).subscribe((res:MMSHttpResponse)=>{
      this.resData = res;
      this.loading =false;
      this._router.navigateByUrl('/login');
        this._snackBar.open(this.resData.successMessage, 'success!!', {
          duration: 5000,
        });
    },error=>{
      console.log(error.error);
      this.loading =false;
      this._snackBar.open(error.error.errorMessage,'', {
        duration: 2000,
        //panelClass:"mat-snack-error"
      });
    });
  }

}
