import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { MMSHttpResponse } from 'src/app/model/response';
import { CustomValidators } from 'src/app/validators/custom-validators';
import { InviteConfirmService } from '../../services/invite-confirm.service';
import { DeeplinkService } from  'src/app/pages/services/deeplink.service';

@Component({
  selector: 'app-invite-confirm',
  templateUrl: './invite-confirm.component.html',
  styleUrls: ['./invite-confirm.component.scss']
})
export class InviteConfirmComponent implements OnInit {


  inviteComfirmForm:FormGroup;
  invitepwdHide = true;
  invitecpwdHide = true;
  inviteConfirmed = false;
  inviteConfirmedError = false;
  loading = false;
  email:string;
  token:string;
  checksum:any;
  resData: MMSHttpResponse

  constructor(fb:FormBuilder,route: ActivatedRoute, private _router: Router, 
    private _inviteConfirmService : InviteConfirmService, private _snackBar:MatSnackBar,private _deviceService:DeeplinkService) { 
    this.email  = route.snapshot.queryParams.email;
    this.token = route.snapshot.queryParams.token;
    this.checksum = route.snapshot.queryParams.checksum;

    this.inviteComfirmForm = fb.group({
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      password: ['', [Validators.required,
        CustomValidators.patternValidator(/\d/, { hasNumber: true }),
        CustomValidators.patternValidator(/[A-Z]/, { hasCapitalCase: true }),
        CustomValidators.patternValidator(/[a-z]/, { hasSmallCase: true }),
        Validators.minLength(8),
        CustomValidators.patternValidator(/[*@!#%&()^~{}+=$-'`<>_?.;:]+/,{hasSpecialCharacter:true})
      ]],
      confirmPassword: ['',[Validators.required,CustomValidators.passwordSame()]],
      mobileNumber: ['', [Validators.required,CustomValidators.mobileNumberValidator()]]
    });
  }
  
  ngOnInit(): void {
  }


  onSubmit(){
    // stop here if form is invalid
    if (this.inviteComfirmForm.invalid) {
      return false;
    }
    this.confirminvitation(this.email,this.token,this.checksum);
  }
  
  confirminvitation(email:string,token:string,checksum:any){
    
    this.loading = true; 
   
    this._inviteConfirmService.confirmUserInvitation(
      this.inviteComfirmForm.value.firstname,
      this.inviteComfirmForm.value.lastname,
         this.inviteComfirmForm.value.password,
         this.inviteComfirmForm.value.mobileNumber,
         email,checksum,token).
         subscribe((res:any)=> {
          setTimeout(()=>{
            this.resData = res;
           console.log("data", this.resData);
          if (this.resData.successMessage !== null) {
            this.inviteConfirmed = true;
            this.inviteConfirmedError = true;
          this._snackBar.open(this.resData.successMessage, '', {
           duration: 5000
          });
          this.loading = false;
        }
        else {
          this._snackBar.open(this.resData.errorMessage, '', {
             duration: 5000
           });
           this.loading = false;
         }
          this.loading = false;
          this.inviteComfirmForm.reset();
          },7000)
        
         
        }, err => {
            console.log(err);
            this.inviteConfirmedError = true;
            this._snackBar.open(err.error.errorMessage, 'error!!', {
                duration: 5000,
              });
              this.loading = false;
            });
  
}
goToApp(){
  var AndriodDeeplinkurl = 'http://com.tevapp/goToLogin'
  var IosDeeplinkurl =  'com.tevapp://goToSignin'
  this._deviceService.deeplink(AndriodDeeplinkurl,IosDeeplinkurl)
}
omit_special_char(event)
{   
   var k;  
   k = event.charCode;  //         k = event.keyCode;  (Both can be used)
   return((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 ); 
}
   
}
