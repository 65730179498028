<img class="logo" src="../../../../assets/logo/IMPACT-by-honeywell.png">

<div class="centered">
  <div style="padding:35px;text-align: center;">
    <div class="tev-title">Set your password</div>

    <div class="login-container">
      <form autocomplete="do-not-show" [formGroup]="resetPwdForm" (ngSubmit)="onSubmit()">
        <mat-form-field appearance="outline">
          <mat-label>Password</mat-label>
          <input matInput [type]="pHide ? 'password' : 'text'" placeholder="Enter a password" formControlName="password"
            name="password" autocomplete="do-not-show">

          <button type="button" mat-icon-button matSuffix (click)="pHide = !pHide" [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="pHide">
            <mat-icon>{{pHide ? 'visibility_off' : 'visibility'}}</mat-icon>
          </button>
        </mat-form-field>
        <div style="text-align: left;
        font-size: 0.8em;
        opacity: 0.8;margin-top:-18px" class="password-policy">
          <div
            [ngClass]="resetPwdForm.controls['password'].hasError('required') || resetPwdForm.controls['password'].hasError('hasCapitalCase')  ? 'error' : 'success'">
            <i class="fas fa-check-circle"></i><i class="fas fa-exclamation-circle"></i>One Upper case</div>
          <div
            [ngClass]="resetPwdForm.controls['password'].hasError('required') || resetPwdForm.controls['password'].hasError('hasSmallCase')  ? 'error' : 'success'">
            <i class="fas fa-check-circle"></i><i class="fas fa-exclamation-circle"></i>One Small case</div>
          <div
            [ngClass]="resetPwdForm.controls['password'].hasError('required') || resetPwdForm.controls['password'].hasError('hasNumber')  ? 'error' : 'success'">
            <i class="fas fa-check-circle"></i><i class="fas fa-exclamation-circle"></i>One Numeric character</div>
          <div
            [ngClass]="resetPwdForm.controls['password'].hasError('required') || resetPwdForm.controls['password'].hasError('hasSpecialCharacter')  ? 'error' : 'success'">
            <i class="fas fa-check-circle"></i><i class="fas fa-exclamation-circle"></i>One Special character</div>

          <div
            [ngClass]="resetPwdForm.controls['password'].hasError('required') || resetPwdForm.controls['password'].hasError('minlength')  ? 'error' : 'success'">
            <i class="fas fa-check-circle"></i><i class="fas fa-exclamation-circle"></i>Minimum length 8</div>
        </div>
        <br>
        <mat-form-field appearance="outline">
          <mat-label>Confirm Password</mat-label>
          <input matInput [type]="cpHide ? 'password' : 'text'" placeholder="Enter the password again"
            formControlName="confirmPassword" autocomplete="do-not-show-anything">
          <button type="button" mat-icon-button matSuffix (click)="cpHide = !cpHide"
            [attr.aria-label]="'Hide confirm password'" [attr.aria-pressed]="cpHide">
            <mat-icon>{{cpHide ? 'visibility_off' : 'visibility'}}</mat-icon>
          </button>
          <mat-error *ngIf="resetPwdForm.controls.confirmPassword.hasError('required')">
            Confirm Password is required
          </mat-error>
          <mat-error
            *ngIf="resetPwdForm.controls.confirmPassword.hasError('confirmPassword') && !resetPwdForm.controls.confirmPassword.hasError('required')">
            Confirm Password does not match password
          </mat-error>
        </mat-form-field>
        <br>

        <button mat-flat-button class="login-button" type="submit" [disabled]="loading">Submit
          
            <mat-spinner *ngIf="loading" style="display:inline-block;position: absolute;margin-left:10px;top:9px;" class="custom" diameter="18">
            </mat-spinner>
         
        </button>

      </form>
    </div>
  </div>
</div>
