import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageRoutingModule } from './page-routing.module';
import { MaterialModule } from '../material.module';
import { InviteUserComponent } from './component/invite-user/invite-user.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChangePasswordComponent } from './component/change-password/change-password.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { LayoutModule } from '@angular/cdk/layout';
import { FilterPipe } from '../pipes/filter.pipe';
import { SingularPipe } from '../pipes/singular.pipe';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatListModule } from '@angular/material/list';
import { HomeComponent } from './component/home/home.component';


@NgModule({
  declarations: [InviteUserComponent,
    ChangePasswordComponent,
    FilterPipe,
    SingularPipe,
    HomeComponent],
  imports: [
    CommonModule,
    FormsModule,
    PageRoutingModule,
    MaterialModule,
    ReactiveFormsModule,
    MatGridListModule,
    MatCardModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    LayoutModule,
    CommonModule,
    MatDialogModule,
    MatButtonToggleModule,
    MatInputModule,
    MatFormFieldModule,
    MatListModule

  ],
  exports: [
    MatInputModule,
    MatFormFieldModule
  ]
})
export class PageModule { }
