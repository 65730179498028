import { Injectable } from '@angular/core';
import { Observable, throwError,of } from 'rxjs';
import { AuthToken } from '../../model/authtoken';
import { MMSHttpResponse, MMSHttpResponseGeneric } from '../../model/response';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError, tap } from 'rxjs/operators';
import { UserAccount } from 'src/app/model/user';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private loggedUser: string;
  private mmsHttpReasponse : MMSHttpResponse;

  constructor(private http: HttpClient) {}

  validateOrgName(orgName:string):Observable<MMSHttpResponse>{
    var res= this.http.get<MMSHttpResponse>(environment.authBaseUrl+`/api/user/validateOrgName/${orgName}`)
    .pipe(
      catchError(this.handleError)
    );
    return res;
  }
  
  createAccount(userAccount: UserAccount ): Observable<MMSHttpResponse> {
    
    var res= this.http.post<MMSHttpResponse>(environment.authBaseUrl+'/api/user/register', userAccount)
    .pipe(
      catchError(this.handleError)
    );
    return res;
  }
  
  login(user:{clientId: string,email: string, password: string }): Observable<MMSHttpResponseGeneric<AuthToken>> {
    var res=  this.http.post<MMSHttpResponseGeneric<AuthToken>>(environment.authBaseUrl+'/api/auth/token', user)
      .pipe(
        catchError(this.handleError)
       );
    return res;
  }

  logout(): Observable<MMSHttpResponse> {
      let clientId = this.getClientId();
      return this.http.get<MMSHttpResponse>(environment.authBaseUrl+'/api/auth/logout?clientId='+ clientId)
      .pipe(
        catchError(this.handleError)
       );
  }
  
  emailResetLink(data:{email:string,redirectUri:string}):Observable<MMSHttpResponse>{
    
    return this.http.post<MMSHttpResponse>(environment.authBaseUrl+'/api/password/EmailResetLink',data)
    .pipe(
      catchError(this.handleError)
    );
  }

  resetPassword(data:{email:string,token:string,newPassword:string}):Observable<MMSHttpResponse>{
    
    return this.http.post<MMSHttpResponse>(environment.authBaseUrl+'/api/password/Reset',data)
    .pipe(
      catchError(this.handleError)
    );
  }

  isLoggedIn() {
    return !!this.getJwtToken();
  }

  refreshToken() {
    return this.http.post<MMSHttpResponseGeneric<AuthToken>>(environment.authBaseUrl+'/api/auth/refresh',{
      'clientId':this.getClientId(),'refreshToken':this.getRefreshToken()
    }).pipe(tap((data: MMSHttpResponseGeneric<AuthToken>)=>{
      this.storeJwtToken(data.responseBody.accessToken);
    }));
  }

  getJwtToken() {
    return localStorage.getItem(environment.JWT_TOKEN);
  }

  getRefreshToken(){
    return localStorage.getItem(environment.REFRESH_TOKEN);
  }

  getClientId(){
    return localStorage.getItem(environment.CLIENT_ID);
  }

  storeJwtToken(token:string){
    localStorage.setItem(environment.JWT_TOKEN, token);
  }

  storeClientId(clientId:string){
    localStorage.setItem(environment.CLIENT_ID, clientId);
  }


  doLoginUser(clientId: string, email: string, tokens: MMSHttpResponseGeneric<AuthToken>) {
    
    this.loggedUser = email;
    this.storeTokens(clientId, tokens.responseBody);
  }

  doLogoutUser() {
    
    this.loggedUser = null;
    this.removeTokens();
  }

  private handleError(error: HttpErrorResponse) {
    
    console.log("error",error);
    return throwError(error);
  }

  private storeTokens(clientId: string, tokens: AuthToken) {
    localStorage.setItem(environment.JWT_TOKEN, tokens.accessToken);
    localStorage.setItem(environment.REFRESH_TOKEN, tokens.refreshToken);
    localStorage.setItem(environment.CLIENT_ID, clientId);
  }

  private removeTokens() {
    localStorage.removeItem(environment.JWT_TOKEN);
    localStorage.removeItem(environment.REFRESH_TOKEN);
    localStorage.removeItem(environment.CLIENT_ID);
  }

}
