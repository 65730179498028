import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MMSHttpResponse } from 'src/app/model/response';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ChangePasswordService {

  constructor(private http: HttpClient) { 
  }


  changeUserPassword(currentPassword: string, newPassword: string ): Observable<MMSHttpResponse> {
    return this.http.post<MMSHttpResponse>(environment.authBaseUrl+'/api/password/Change', {"currentPassword":currentPassword,"newPassword":newPassword,"clientId":"tev.webui"})
      .pipe(
        catchError(this.handleError)
       );
  }
  
  private handleError(error: HttpErrorResponse) {
    return throwError(error);
  }

}
