import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})

export class DeeplinkService {
 constructor() {}
 deeplink(deeplinkurl,iosDeepLinkUrl) {
   let ua = navigator.userAgent.toLowerCase();
   let isAndroid = ua.indexOf("android") > -1;
   let isIphone = ua.indexOf("iphone") > -1; 
            if (isIphone == true) {
                let app = { 
                launchApp: function() {
                    setTimeout(function () { window.open("https://itunes.apple.com/") }, 650);
                    window.open(iosDeepLinkUrl);
                }
            };
            app.launchApp();
            } else if (isAndroid== true) {
                let app = { 
                launchApp: function() {
                  setTimeout(function () { window.open("https://play.google.com/store/apps/details?id=com.honeywell.hbt.impact") }, 650);
                  window.open(deeplinkurl);
                  
                }
            };
            app.launchApp();
            }else{
                window.location.href ='/page/device-list'
            }
 }
}