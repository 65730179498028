import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AbstractControl, AsyncValidator, AsyncValidatorFn, FormControl, FormGroup, ValidationErrors, ValidatorFn } from "@angular/forms";
import { BehaviorSubject, of, throwError } from "rxjs";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { AuthService } from "../auth/services/auth.service";

@Injectable({ providedIn: 'root' })
export class CustomValidators {
  constructor(private authService: AuthService) {}
    static gstinValidator(): ValidatorFn {
        return (control: AbstractControl): {[key: string]: any} | null => {
          var pat= /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/;
          const result = new RegExp(pat).test(control.value);
          if(result){
              return null;
          }
          else{
              return  {gstin: {value: control.value}}
          }
        };
      }

      static passwordSame(): ValidatorFn {
        return (control: FormControl): {[key: string]: any} | null => {
          if(!control || !control.parent){
            return null;
          }
          if(control.value==control.parent.controls['password'].value){
              return null;
          }
          else{
              return  {confirmPassword: true}
          }
        };
      }

      static passwordPolicy():ValidatorFn{
        return (control: AbstractControl): {[key: string]: any} | null => {
          var pat= /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/;
          const result = new RegExp(pat).test(control.value);
          if(result){
              return null;
          }
          else{
              return  {password: {value: control.value}}
          }
        };
      }

      static mobileNumberValidator():ValidatorFn{
        return (control:AbstractControl):{[key:string]:any} | null=>{
          var pat=/^[6-9]\d{9}$/;
          const result = new RegExp(pat).test(control.value);
          if(result){
              return null;
          }
          else{
              return  {mobileNumber: {value: control.value}}
          }
        }
      }

      orgNameUnique():AsyncValidatorFn{
        return (control: AbstractControl): Observable<ValidationErrors | null> => {
          var obs = this.authService.validateOrgName(control.value).pipe(
            map((res)=>{
              console.log(res);
              if(res.successMessage=="true"){
                return {orgName:true};
              }
              return null;
            }),
            catchError(err=>{
              return throwError(err);
            })
          )
          return obs;
        };
      }

      static specialCharacterValidator(): ValidatorFn{
        return (control:AbstractControl):{[key:string]:any} | null=>{
          var pat = /^[A-Za-z0-9-+%!@.,&$#]*$/;
          const result = new RegExp(pat).test(control.value);
          if(result){
              return null;
          }
          else{
              return  {orgName: {value: control.value}}
          }
        }
      }

      static firstNameValidator(): ValidatorFn {
        return (control:AbstractControl):{[key:string]:any} | null=>{
          var pat = /^[A-Za-z0-9 ]*$/;
          //var pat=/^[ A-Za-z0-9_@.#&+-%!]*$/;
          const result = new RegExp(pat).test(control.value);
          if(result){
              return null;
          }
          else{
              return  {firstName: {value: control.value}}
          }
        }
      }

      static lastNameValidator(): ValidatorFn {
        return (control:AbstractControl):{[key:string]:any} | null=>{
          var pat = /^[A-Za-z0-9 ]*$/;
          const result = new RegExp(pat).test(control.value);
          if(result){
              return null;
          }
          else{
              return  {lastName: {value: control.value}}
          }
        }
      }

      static patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
          if (!control.value) {
            // if control is empty return no error
            return null;
          }
      
          // test the value of the control against the regexp supplied
          const valid = regex.test(control.value);
      
          // if true, return no error (no error), else return error passed in the second parameter
          return valid ? null : error;
        };
      }
}  



