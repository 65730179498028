<img class="logo" src="../../../../assets/logo/IMPACT-by-honeywell.png">

<div class="centered">
    <mat-card class="shadow" style="text-align: center;padding:50px;">
        <div class="tev-title">Reset your password</div>
        <br>
        <div class="login-container">
            <form [formGroup]="forgotPwdForm" (ngSubmit)="onSubmit()">
                <mat-form-field appearance="outline">
                    <mat-label>Email</mat-label>
                    <input matInput placeholder="Enter your email" formControlName="email">
                   
                        <mat-icon matSuffix>email</mat-icon>
                        <mat-error *ngIf="forgotPwdForm.controls.email.hasError('email') && !forgotPwdForm.controls.email.hasError('required')">
                            Please enter a valid email address
                          </mat-error>
                          <mat-error *ngIf="forgotPwdForm.controls.email.hasError('required')">
                            Email is required
                          </mat-error>
                </mat-form-field>
                <br>
                <mat-form-field appearance="outline" *ngIf="!hide">
                    <input matInput placeholder="enter your email" formControlName="redirectUri">
                </mat-form-field>
                
                <button  style="margin-top:10px;width:150px;" mat-flat-button class="login-button" type="submit">
                    Submit
                    
                        <mat-spinner *ngIf="loading" style="display:inline-block;position: absolute;margin-left:10px;top:10px;" class="custom" color="primary" diameter="18">
                        </mat-spinner>
                    
                </button>
                
            </form>
        </div>
    </mat-card>
</div>