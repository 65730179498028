import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MMSHttpResponse } from 'src/app/model/response';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InviteConfirmService {

  constructor(private http: HttpClient,private _snackBar: MatSnackBar) { }

  confirmUserInvitation(firstName:string, lastName:string, password:string, mobileNumber:string, email: string, checksum: string, token: string ): Observable<MMSHttpResponse> {
   // var res=  this.http.post<MMSHttpResponse>(environment.authBaseUrl+'/api/Invite/Confirm', {"firstname":firstName,"lastname":lastName,"password":password,
   // "mobileNumber":mobileNumber,"email":email,"checksum":checksum,"token":token})
  //    .pipe(
  //      catchError(this.handleError)
  //     );
  //  return res;
  return this.http.post<MMSHttpResponse>(environment.authBaseUrl+'/api/Invite/Confirm', {"firstname":firstName,"lastname":lastName,"password":password,
   "mobileNumber":mobileNumber,"email":email,"checksum":checksum,"token":token})
     .pipe(
      catchError(this.handleError)
     ); 
     }

  private handleError(error: HttpErrorResponse) {
    return throwError(error);
  }
}
