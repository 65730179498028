import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RandomGuard } from '../auth/guard/random.guard';
import { NavComponent } from '../nav/nav.component';
import { ChangePasswordComponent } from './component/change-password/change-password.component';
import { HomeComponent } from './component/home/home.component';
import { InviteUserComponent } from './component/invite-user/invite-user.component';

const routes: Routes = [
  {
    path:'page/home',
    component:NavComponent,
    children:[
      { 
        path: '', 
        component: HomeComponent,
        canActivate: [RandomGuard],
        canLoad: [RandomGuard]
      }
    ]
  },
  {
    path:'page/change-password',
    component:NavComponent,
    children:[
      { 
        path: '', 
        component: ChangePasswordComponent,
        canActivate: [RandomGuard],
        canLoad: [RandomGuard]
      }
    ]
  },
  {
    path:'page/invite-user',
    component:NavComponent,
    children:[
      { 
        path: '', 
        component: InviteUserComponent,
        canActivate: [RandomGuard],
        canLoad: [RandomGuard]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PageRoutingModule { }
