import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { InviteUser } from 'src/app/model/InviteUser';
import { MMSHttpResponse, MMSHttpResponseGeneric } from 'src/app/model/response';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InviteUserService {

  constructor(private http:HttpClient, private _snackBar:MatSnackBar) { }

  inviteUser(email: string, name: string ): Observable<MMSHttpResponse> {
    return this.http.post<MMSHttpResponse>(environment.authBaseUrl+'/api/Invite/User', {"email":email,"name":name,"redirectUrl":environment.inviteUserRedirectUri,
    "appName": environment.appName})
      .pipe(
        catchError(this.handleError)
      );
  }

  getInvitedUsers(): Observable<MMSHttpResponseGeneric<InviteUser[]>> {
    return this.http.get<MMSHttpResponseGeneric<InviteUser[]>>(environment.authBaseUrl+'/api/Invite/InvitedUsers')
      .pipe(
        catchError(this.handleError)
       );
  }

  resendEmail(email:string): Observable<MMSHttpResponse>{
    return this.http.get<MMSHttpResponse>(environment.authBaseUrl+'/api/Invite/Resend/'+email+'?redirectUri='+environment.inviteUserRedirectUri)
    .pipe(
      catchError(this.handleError)
    );
  }

  private handleError(error: HttpErrorResponse) {
    return throwError(error);
  }

}
