<mat-sidenav-container  class="sidenav-container">
  <mat-sidenav  #drawer class="sidenav" fixedInViewport
      [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
      [mode]="(isHandset$ | async) ? 'over' : 'side'"
      [opened]="(isHandset$ | async) === false">
    <mat-toolbar><img style="width:150px;" src="../../assets/logo/IMPACT-by-honeywell.png"></mat-toolbar>
    <mat-nav-list  class="side-nav">
      <a mat-list-item routerLink="/page/home"><i class="fas fa-home"></i><span class="sidenav-text">Home</span></a>
      <a mat-list-item routerLink="/page/change-password"><i class="fas fa-key"></i><span class="sidenav-text">Change Password</span></a>
      <a mat-list-item routerLink="/page/invite-user"><i class="fas fa-user"></i><span class="sidenav-text">Invite User</span></a>
      <a mat-list-item (click)="logOut()"><i class="fas fa-power-off"></i><span class="sidenav-text">Logout <mat-spinner *ngIf="logoutInProgress" style="display:inline-block;margin-left:10px;vertical-align: middle;" color="warn" diameter="15">
      </mat-spinner></span></a>
     
    </mat-nav-list>
  <div style="color:#666;
  position: fixed;
  bottom: 0;
  font-size: 12px;
  opacity: 0.8;
  margin-left: 10px;">Version: 1.0.1</div>
  </mat-sidenav>

  <mat-sidenav-content>
    <mat-toolbar style="box-shadow: 0 0 4px rgba(0,0,0,0.4);">
      <button style="color:white"
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
        *ngIf="isHandset$ | async">
        <i class="fas fa-bars"></i>
      </button>
      <div class="view-title"></div>
      <i class="far fa-bell notification-icon"></i>
    </mat-toolbar>
    <!-- Add Content Here -->
    <router-outlet></router-outlet>

  </mat-sidenav-content>
      
</mat-sidenav-container>
