import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { MMSHttpResponse } from 'src/app/model/response';
import { CustomValidators } from 'src/app/validators/custom-validators';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  email: string;
  token: string;
  resetPwdForm: FormGroup;
  pHide = true;
  cpHide = true;
  loading = false;
  resData: MMSHttpResponse;

  constructor(route: ActivatedRoute, fb: FormBuilder, private _authService: AuthService,
    private _snackBar: MatSnackBar, private _router: Router) {
    this.email = route.snapshot.queryParams.email;
    this.token = route.snapshot.queryParams.token;
    this.resetPwdForm = fb.group({
      password: ['', [Validators.required,
        CustomValidators.patternValidator(/\d/, { hasNumber: true }),
        CustomValidators.patternValidator(/[A-Z]/, { hasCapitalCase: true }),
        CustomValidators.patternValidator(/[a-z]/, { hasSmallCase: true }),
        Validators.minLength(8),
        CustomValidators.patternValidator(/[*@!#%&()^~{}+=$-'`<>_?.;:]+/,{hasSpecialCharacter:true})
      ]],
      confirmPassword: ['', [Validators.required,CustomValidators.passwordSame()]]
    })
  }

  ngOnInit(): void {
  }

  onSubmit() {
    // stop here if form is invalid
    if (this.resetPwdForm.invalid) {
      return;
    }
    this.loading = true;
    this._authService.resetPassword({ "email": this.email, "token": this.token, "newPassword": this.resetPwdForm.value.password })
      .subscribe((res: MMSHttpResponse) => {
        this.resData = res;
        this.loading = false;
        this._router.navigateByUrl('/reset-password-success');
       
      },err=>{
        this.loading=false;
        this._snackBar.open(err.error.errorMessage, 'Error', {
          duration: 5000
        });
      });
  }

}
