/*

 * Honeywell confidential. For internal use only.

 * Property of and copyright ownership by Honeywell International Inc. and/or its affiliates.

 * HCE Source Code Access Policy [http://go.honeywell.com/hce-code-access-policy].

 * Use of source code must follow all Honeywell policies including the

 * External use or distribution prohibited without written approval per Honeywell policies.

 */
export const environment = {

  production: false,

  authBaseUrl : "https://mmsidentitydev.honeywell.com",

  appName: "TEV",

  clientId:"tev.webui",

  resetPasswordRedirectUri:"https://tevwebdev.honeywell.com/reset-password",

  inviteUserRedirectUri: "https://tevwebdev.honeywell.com/invite-confirm",

  JWT_TOKEN:'AUTH_TOKEN',

  REFRESH_TOKEN:'REFRESH_TOKEN',

  CLIENT_ID:'CLIENT_ID',

  ZOHO_TOKEN:'ZOHO_TOKEN',

  DEVICE_ID:'DEVICE_ID',

  // Zoho related keys

  Zoho_BaseUri:"https://tevapidev.honeywell.com/api/v1/ZohoSubscription",

   //Tev Uri

   Tev_BaseUri:"https://tevapidev.honeywell.com/api/v1",

   Mask:4,

   NoMask:6

};

