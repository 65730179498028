import { Component, Input, OnInit } from '@angular/core';
import { DeeplinkService } from '../../../pages/services/deeplink.service';
import { PlatformLocation } from '@angular/common';


@Component({
  selector: 'reset-password-response',
  templateUrl: './reset-password-response.html',
  styleUrls: ['./reset-password-response.scss']
})
export class ResetPasswordResponseComponent {

  constructor(public _deepLink:DeeplinkService,private location :PlatformLocation) {
    
    location.onPopState((event) => {
      history.forward();
      history.pushState(null, null, location.href);
      return false;
      });
  }
  ngOnInit(): void {
    history.pushState(null, null, location.href);
  }

  goToApp(){
    var AndriodDeeplinkurl = 'http://com.tevapp/goToLogin'// Modified deep link
    var IosDeeplinkurl =  'com.tevapp://goToSignin'
    this._deepLink.deeplink(AndriodDeeplinkurl,IosDeeplinkurl)
  }
}
