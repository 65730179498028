
<div class="grid-container">
    <div class="row">
        <div class="col-lg-6">
        <mat-card class="shadow" style="text-align: center;">
         
         
            <form style="margin:30px" [formGroup]="changePwdForm" (ngSubmit)="onSubmit();">
                <mat-form-field>
                    <input matInput placeholder="Enter current password" formControlName="currentPassword"
                        [type]="crntpwdHide ? 'password' : 'text'">
                    <button type="button" mat-icon-button matSuffix (click)="crntpwdHide = !crntpwdHide"
                        [attr.aria-label]="'Hide current password'" [attr.aria-pressed]="crntpwdHide">
                        <mat-icon>{{crntpwdHide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
                    <mat-error *ngIf="changePwdForm.controls.currentPassword.hasError('required')">
                        Current password is required
                      </mat-error>
                </mat-form-field>
                <mat-form-field>
                    <input matInput placeholder="Enter new password" formControlName="password"
                        [type]="newpwdHide ? 'password' : 'text'">
                    <button type="button" mat-icon-button matSuffix (click)="newpwdHide = !newpwdHide"
                        [attr.aria-label]="'Hide new password'" [attr.aria-pressed]="newpwdHide">
                        <mat-icon>{{newpwdHide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
                    <mat-hint align="start">1 Upper case,</mat-hint>
                    <mat-hint align="start">1 Numeric, 1 Special Character,</mat-hint>
                    <mat-hint align="start">Minimum length 8</mat-hint>
                    <mat-error *ngIf="changePwdForm.controls.password.hasError('required')">
                        Password is required
                      </mat-error>
                      <mat-error *ngIf="changePwdForm.controls.password.hasError('password') && !changePwdForm.controls.password.hasError('required')">
                        Password policy not met
                      </mat-error>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Confirm Password</mat-label>
                    <input matInput placeholder="Enter new password again" formControlName="confirmPassword" 
                        [type]="confirmpwdHide ? 'password' : 'text'">
                    <button type="button" mat-icon-button matSuffix (click)="confirmpwdHide = !confirmpwdHide"
                        [attr.aria-label]="'Hide confirm password'" [attr.aria-pressed]="confirmpwdHide">
                        <mat-icon>{{confirmpwdHide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
                    <mat-error *ngIf="changePwdForm.controls.confirmPassword.hasError('required')">
                        Confirm Password is required
                      </mat-error>
                      <mat-error *ngIf="changePwdForm.controls.confirmPassword.hasError('confirmPassword') && !changePwdForm.controls.confirmPassword.hasError('required')">
                        Confirm Password does not match password
                      </mat-error>
                </mat-form-field>
                
                <button style="margin-top:30px;" mat-flat-button class="login-button" type="submit">Submit
                    <mat-icon *ngIf="loading">
                        <mat-spinner class="custom" style="margin-top:2px;margin-left:5px" color="primary" diameter="15">
                        </mat-spinner>
                    </mat-icon>
                </button>
            </form>
          
        </mat-card>
  </div>
</div>
</div>