<img style="width:180px" class="logo" src="../../../../assets/logo/IMPACT-by-honeywell.png">
<div class="subscription-container">
    <div class="success-checkmark">
        <div class="check-icon">
            <span class="icon-line line-tip"></span>
            <span class="icon-line line-long"></span>
            <div class="icon-circle"></div>
        <div class="icon-fix"></div>
        </div>
    </div>
<div style="text-align: center;font-size: 16px;font-weight: 300;">Password reset successfull!</div>
<button mat-flat-button class="login-button" style="margin-top: 30px;
border: none;
padding: 10px;
border-radius: 3px;;"class="go-back" (click)="goToApp()" >Go back to the app</button>
</div>

